import React, { Component } from "react";

export class EventTitle extends Component {
  render() {
    const { name } = this.props;

    const title = name ? name.replace(/(?:\r\n|\r|\n)/g, "<br />") : "";

    return (
      <div className="row">
        <div className="col s12 m9">
          <div className="info-card__title">{title}</div>
        </div>
      </div>
    );
  }
}
