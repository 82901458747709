import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import { Intent } from "@blueprintjs/core";
import { configureHeader, showToast, createModal } from "@actions/ui";
import { getRandomContent } from "@utils/random";
import { updateFilter } from "@actions/update-filter";
import { recommendEvent } from "@actions/dashboard/recommend-event";
import { stripHTML } from "@utils/strings";
import EventDetailInfoCard from "@components/modules/event-detail/event-detail-info-card";
import EventDetailContent from "@components/modules/event-detail/event-detail-content";
import SplitContentModule from "@components/modules/common/split-content-module";
import Footer from "@components/global/footer/footer";
import {
  EVENT_STATE_PUBLISHED,
  EVENT_REVIEW_RECOMMEND,
} from "@constants/config";
import { getAllCategoryFilters } from "@utils/filter-utils";
import { getActiveLocations } from "@selectors/filters";
import { ProfileEditBlock } from "@containers/profile";
import { setTfCheckoutReactConfigs } from "../../utils/utils";
import { navigate } from "gatsby";
import { getEvent } from "../../utils/http_service";
import { META } from "../../constants/config";
import { getBrand, getEventsVersionOne } from "../../utils/http_service/events";
import { currentLocation } from "../../selectors/filters";

setTfCheckoutReactConfigs();

const TYPE_PREVIEW = "PREVIEW";
const specialPages = ["cf46bf3dca7c691a9137", "b85cb4cc0d3b99f58ed9", "cloak"];

class EventDetail extends Component {
  state = {
    events: []
  }
  constructor(props) {
    super(props);
    this.detailType = this.setDetailType();
    if (specialPages.includes(this.props.slug || this.props.deeplinkSlug)) {
      const body = document.body;
      body.classList.add("custom");
    }
  }

  setDetailType = () => {
    const searchParams = new URLSearchParams(window?.location?.search);
    const eventState = searchParams.get("event_state");
    return eventState ? TYPE_PREVIEW : "";
  };

  componentDidMount = async () => {
    if (!this.props.serverData.ttfEvent.id) {
      navigate("/events")
      return
    }
    const { configureHeader } = this.props;
    window && window.scrollTo(0, 0);
    configureHeader({
      headerVisibilityLocked: false,
      headerTheme: "black",
    });

    const interval = setInterval(async () => {
      const currLocation = _get(this.props.currentRegion, 'value', null)
      if (currLocation) {
        clearInterval(interval)
        const { events } = await getEventsVersionOne({
          rn_filters: {
            region: currLocation,
            is_recommendations: true
          },
          size: 4
        })
        this.setState({ events: getRandomContent(this.getOtherEvents(events), 3) })
      }
    }, 100)
  };

  getOtherEvents = (events) => {
    const {
      serverData: { ttfEvent },
    } = this.props;

    return events.filter((event) => {
      return (
        event.id !== ttfEvent.id && event.event_state === EVENT_STATE_PUBLISHED
      );
    });
  };

  recommendEvent = async (obj) => {
    const {
      recommendEvent,
      showToast,
      serverData: {
        ttfEvent: { id },
      },
    } = this.props;

    try {
      await recommendEvent(obj.value === EVENT_REVIEW_RECOMMEND, Number(id));
      showToast("Success", Intent.SUCCESS);
    } catch (error) {
      const { isAxiosError, response } = error;
      if (isAxiosError && response && response.status === 403) {
        const { data: { message = "" } = {} } = response;
        const messageEl = (
          <div>
            <p>
              {message}.
              <span
                className="edit-profile-link"
                onClick={() => {
                  import("../../containers/global/global-toaster-create").then(
                    (globalToaster) => {
                      globalToaster.default.clear();
                    }
                  );
                  this.openModal();
                }}
              >
                EDIT PROFILE
              </span>
            </p>
          </div>
        );
        showToast(messageEl, Intent.WARNING, true);
      } else {
        showToast("Something Wrong", Intent.DANGER);
      }
    }
  };

  didRecommend = () => {
    const {
      auth: {
        me: { exist, data },
      },
      serverData: {
        ttfEvent: { id },
      },
    } = this.props;

    const recommendedEvents = _get(data, "recommendedEvents", []);

    if (exist) {
      return recommendedEvents.includes(Number(id));
    }
    return false;
  };

  openModal = () => {
    const { createModal, close_others } = this.props;
    const modal = {
      id: "profile_edit",
      Component: ProfileEditBlock,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    createModal(modal, close_others);
  };

  render() {
    const {
      filters,
      locationFilters,
      serverData: { ttfEvent, ttfEventVenue, ttfEventTickets, ttfBrand },
      slug,
    } = this.props;
    const categoryFilters = getAllCategoryFilters(filters);
    let heroImg = "";
    if (ttfEvent.banner) {
      heroImg = ttfEvent.banner.url;
    } else if (ttfEvent.square) {
      heroImg = ttfEvent.square.url;
    } else if (ttfEvent.banner_image) {
      heroImg = ttfEvent.banner_image;
    }

    const metaDesciption = stripHTML(
      ttfEvent.brief && ttfEvent.brief !== ""
        ? ttfEvent.brief
        : ttfEvent.description
    );
    const recommenderCurators = ttfEvent ? ttfEvent.recommended_customers : [];
    return (
      <div className="event-detail">
        {
          <Helmet>
            <title>{`${META.title} | Event | ${ttfEvent.name || ttfEvent.title
              }`}</title>
            <link
              rel="canonical"
              href={`${process.env.GATSBY_TTF_SITE_URL}/events/${ttfEvent.id}`}
            />
            <meta name="description" content={metaDesciption} />
            <meta name="og:description" content={metaDesciption} />
            <meta
              property="og:title"
              content={`${META.title} | Event | ${ttfEvent.name || ttfEvent.title
                }`}
            />
            {heroImg && heroImg !== "" && (
              <meta property="og:image" content={heroImg} />
            )}
          </Helmet>
        }
        <div
          className="event-detail__hero"
          onClick={() => {
            navigate("/events");
          }}
        >
          {heroImg !== "" && <img alt="" src={heroImg} />}
          <div className="hero-gradient-overlay" />
        </div>
        <EventDetailInfoCard
          browser={this.props.browser}
          specialPages={specialPages}
          recommenderCurators={recommenderCurators}
          ttfVenue={ttfEventVenue}
          ttfEvent={ttfEvent}
          tickets={ttfEventTickets}
          recommendEvent={this.recommendEvent}
          didRecommend={this.didRecommend}
        />
        <EventDetailContent
          updateFilter={this.props.updateFilter}
          auth={this.props.auth}
          categoryFilters={categoryFilters}
          browser={this.props.browser}
          platform={this.props.platform}
          ttfEvent={ttfEvent}
          locationFilters={locationFilters}
          tickets={ttfEventTickets}
          recommendEvent={this.recommendEvent}
          didRecommend={this.didRecommend}
          tags=""
          brand={ttfBrand}
          slug={slug}
        />
        <SplitContentModule
          moduleClass={
            "container container__related-events container__fullwidth"
          }
          navigate={() => navigate("/events")}
          updateFilter={this.props.updateFilter}
          filterValue={{
            type: "sort",
            value: "recommended",
          }}
          events={this.state.events}
          categoryFilters={categoryFilters}
          headline={"You might also be into"}
          description={""}
          listType="grid"
        />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    browser: state.browser,
    platform: state.platform,
    filters: state.eventFilters,
    locationFilters: getActiveLocations(state),
    events: state.events.data,
    currentRegion: currentLocation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
    recommendEvent: (recommend, id) => {
      return dispatch(recommendEvent(recommend, id));
    },
    showToast: (message, intentStyle, forever) => {
      dispatch(showToast(message, intentStyle, forever));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
  };
};

const EventRedux = connect(mapStateToProps, mapDispatchToProps)(EventDetail);

export default EventRedux;

export async function getServerData(props) {
  try {
    const slug = props?.params?.slug;
    const ttfEventData = await getEvent(slug, props.query.pk);
    const ttfBrand = await getBrand(ttfEventData.event.brand_slug);
    return {
      props: {
        ttfEvent: _get(ttfEventData, "event"),
        ttfEventVenue: _get(ttfEventData, "venue"),
        ttfEventTickets: _get(ttfEventData, "tickets"),
        ttfBrand,
        slug,
      },
    };
  } catch (error) {
    return {
      props: { error: true, message: error, ttfEvent: {}, ttfEventVenue: {}, ttfEventTickets: {}, ttfBrand: { displayName: "" } },
    };
  }
}
