import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import EventTile from '@components/global/tiles/EventTile'
import { LANDSCAPE_TILE } from '@constants/content-tile-types'

import { setListViewType } from '../../../actions/ui'

class SplitContentModule extends Component {
  static propTypes = {
    events: PropTypes.array,
    headline: PropTypes.string,
    moduleClass: PropTypes.string,
    description: PropTypes.string,
    categoryFilters: PropTypes.array,
    sidebarModule: PropTypes.element,
    navigate: PropTypes.func,
    updateFilter: PropTypes.func,
    filterValue: PropTypes.object,
    listType: PropTypes.string
  }

  componentDidMount = () => {
    this.props.setListViewType('list')
  }

  renderEvents = () => {
    const { events } = this.props
    const eventsArr = events.map((event, i) => {
      return (
        <EventTile
          key={String(event.id + i)}
          type={LANDSCAPE_TILE}
          column={`col s12 col--no-margin-top tile-${event.id}`}
          categoryFilters={this.props.categoryFilters}
          listType={this.props.listType}
          {...event}
        />
      )
    })

    return eventsArr
  }

  render() {
    const { headline, description, moduleClass, sidebarModule } = this.props

    return (
      <div className={moduleClass}>
        <div className="container">
          <div className="row">
            <div className="col s12 m12 l9 col--no-margin-bottom">
              <div className="row">
                <div className="col s12 col--no-margin-bottom">
                  <div className="split-content__top-divider"></div>
                </div>
                <div className="col s12 m4 heading heading--secondary">
                  <h2 className="heading__headline">
                    <span>{headline}</span>
                  </h2>
                  <p className="heading__description">{description}</p>
                  <button
                    onClick={() => {
                      this.props.updateFilter(this.props.filterValue)
                      this.props.navigate()
                    }}
                    className="heading__see-all"
                  >
                    SEE ALL
                  </button>
                </div>
                <div className="col s12 m8 col--no-margin-bottom suggested-events-list">
                  <div className="row">{this.renderEvents()}</div>
                </div>
              </div>
            </div>
            <div className="col s12 m8 offset-m4 l3 col--no-margin-bottom">{sidebarModule}</div>
          </div>
        </div>
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    setListViewType: type => {
      dispatch(setListViewType(type))
    }
  }
}

export default connect(null, mapDispatchToProps)(SplitContentModule)