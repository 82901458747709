import React, { Component } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import moment from "moment-timezone";
import _get from "lodash/get";
import _some from "lodash/some";
import {
  DEFAULT_AVATAR,
  EVENT_REVIEW_RECOMMEND,
  EVENT_REVIEW_UNRECOMMEND,
} from "@constants/config";
import { EventTitle } from "./event-details-info/event-title";
import { EventStats } from "./event-details-info/EventStats/index";

class EventDetailInfoCard extends Component {
  getDate = () => {
    const start = moment.tz(
      this.props.ttfEvent.start_date,
      this.props.ttfEvent.timezone
    );
    const end = moment.tz(
      this.props.ttfEvent.end_date,
      this.props.ttfEvent.timezone
    );

    if (end.diff(start, "days") >= 1) {
      const start_min =
        start.format("mm") === "00" ? "" : ":" + start.format("mm");
      const end_min = end.format("mm") === "00" ? "" : ":" + end.format("mm");

      return (
        <>
          {`${start.format("ddd")} ${start.format("MMM")} ${start.format(
            "DD"
          )}, ${start.format("h")}${start_min}${start.format("A")} - `}
          <br />
          {`${end.format("ddd")} ${end.format("MMM")} ${end.format(
            "DD"
          )}, ${end.format("h")}${end_min}${end.format("A")}`}
        </>
      );
    }

    return (
      <>{`${start.format("dddd")}, ${start.format("MMMM")} ${start.format(
        "DD"
      )}`}</>
    );
  };

  getTimes = () => {
    const start = moment.tz(
      this.props.ttfEvent.start_date,
      this.props.ttfEvent.timezone
    );
    const end = moment.tz(
      this.props.ttfEvent.end_date,
      this.props.ttfEvent.timezone
    );

    if (end.diff(start, "days") >= 1) {
      return "";
    }

    const start_min =
      start.format("mm") === "00" ? "" : ":" + start.format("mm");

    const end_min = end.format("mm") === "00" ? "" : ":" + end.format("mm");
    return (
      start.format("h") +
      start_min +
      start.format("A") +
      " - " +
      end.format("h") +
      end_min +
      end.format("A")
    );
  };

  renderRecos = () => {
    const { recommenderCurators = [] } = this.props;
    if (!recommenderCurators.length) {
      return [];
    }

    return recommenderCurators.map((curator) => {
      const { username, image, first_name = "", last_name = "" } = curator;
      const userUrl = `/u/${username}`;
      const img = image || DEFAULT_AVATAR;
      const fullName = `${first_name} ${last_name}`;

      return (
        <div
          title={fullName}
          key={curator.id}
          className="info-card__recommander"
        >
          <Link to={userUrl}>
            <img src={img} alt="" />
          </Link>
        </div>
      );
    });
  };

  getRecommendSection = () => {
    const {
      me: { exist },
      ttfEvent: { id },
      browser,
      tickets,
      recommendEvent,
    } = this.props;
    const isMobile = browser.lessThan.mediumSmall || browser.is.mediumSmall;

    if (!exist || !id || isMobile) {
      return null;
    }

    const didRecommend = this.props.didRecommend();
    const isTicketsEmpty = !(tickets && tickets.length);
    const isAllTicketsSoldOut = !_some(
      tickets,
      (item) => !(item.sold_out || item.soldOut)
    );

    return (
      <span
        className="info-card__curator-controls"
        style={{
          bottom:
            isAllTicketsSoldOut || isTicketsEmpty
              ? 25
              : browser.is.mobile
              ? 50
              : 25,
        }}
      >
        <button
          onClick={() =>
            recommendEvent({
              value: didRecommend
                ? EVENT_REVIEW_UNRECOMMEND
                : EVENT_REVIEW_RECOMMEND,
            })
          }
          className="btn info-card__reco-btn"
        >
          {didRecommend ? "UNRECOMMEND" : "RECOMMEND"}
        </button>
      </span>
    );
  };

  render() {
    const { specialPages, platform, ttfVenue, ttfEvent } = this.props;

    let infoCardImg = "";
    if (ttfEvent.flyer_image) {
      infoCardImg = ttfEvent.flyer_image;
    } else if (ttfEvent.square && ttfEvent.square.url) {
      infoCardImg = ttfEvent.square.url;
    } else if (ttfEvent.banner && ttfEvent.banner.url) {
      infoCardImg = ttfEvent.banner.url;
    }

    let recos = this.renderRecos();
    const startTime = ttfEvent.start_date ? this.getDate() : "";
    const times = startTime ? this.getTimes() : "";
    const isSpecial = !!(
      specialPages.includes(ttfEvent.slug) || specialPages.includes(ttfEvent.id)
    );
    const externalTicketsUrl = _get(ttfEvent, "redirect_url");

    return (
      <div className="container container__info-card">
        <div className="row">
          <div className="col s12 m4">
            <div className="info-card__image">
              {infoCardImg !== "" && <img src={infoCardImg} alt="" />}
            </div>
          </div>
          <div className="col s12 m8 info-card-col">
            <EventTitle name={ttfEvent.name} />
            <EventStats
              id={ttfEvent.id}
              name={ttfEvent.name}
              isSpecial={isSpecial}
              startTime={startTime}
              minimumAge={ttfEvent.minimum_age}
              rsvp_url={
                ttfEvent?.metadata?.rn_event_metadata
                  ? JSON.parse(ttfEvent?.metadata?.rn_event_metadata)?.rsvpUrl
                  : undefined
              }
              platform={platform}
              fb_url={ttfEvent.facebook_event}
              times={times}
              ttfVenue={ttfVenue}
              timezone={ttfEvent.timezone}
            />
            <div className="row row__misc">
              <div className="col s12 m6 l7">
                {recos.length > 0 && (
                  <div className="info-card__label--no-border">
                    {"Recommended by"}
                  </div>
                )}
                {recos.length > 0 && (
                  <div className="info-card__recommendations">{recos}</div>
                )}
              </div>
              <div className="col s12 m6 l5">
                {this.getRecommendSection()}
                <div className="row row__external-tickets">
                  <div className="col s12">
                    {!this.props.blast_id && externalTicketsUrl && (
                      <a
                        href={externalTicketsUrl}
                        className="link__more-info"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn external-ticket-btn">
                          {"TICKETS"}
                        </button>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.me,
  };
};

export default connect(mapStateToProps, null)(EventDetailInfoCard);
