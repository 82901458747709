import React from "react";
import RecommendedIcon from "@assets/svg/svgui/transparent-heart-icon.svg";
import UnrecommendedIcon from "@assets/svg/svgui/broken-heart-icon.svg";

export default function ActionsSectionComponent({
  handleGetTicketClick = () => { },
  handleDidRecommendClick = () => { },
  recommendState = false,
  hideRecommendSide = false,
  hideGetTicketsSide = false,
  isTicketOnSale = true,
  isTicketAvailable = true,
  isLoading = false
}) {
  const hideGetTickets = (hideGetTicketsSide || !isTicketOnSale) && !isTicketAvailable

  if (hideRecommendSide && hideGetTickets) {
    return null;
  }

  return (
    <div className="action-section-container">
      {!hideGetTickets && (
        <div className="get-tickets-section" onClick={handleGetTicketClick}>
          <p>{isLoading ? "We\u2019re getting your tickets now…" : "GET TICKETS"}</p>
        </div>
      )}
      {!hideRecommendSide && (
        <div
          className={`recommend-unrecommend-section ${hideGetTickets ? "did-recommend-full-width" : ""
            }`}
          onClick={handleDidRecommendClick}
        >
          <div>
            <div className="SVGInline">
              {recommendState ? (
                <UnrecommendedIcon
                  className="SVGInline-svg"
                  style={{ width: 38, height: 35 }}
                />
              ) : (
                <RecommendedIcon
                  className="SVGInline-svg"
                  style={{ width: 38, height: 35 }}
                />
              )}
            </div>
            <p className="action-text">
              {recommendState ? "UNRECOMMEND" : "RECOMMEND"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
