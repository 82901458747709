import React from "react";

export const DateTimeComponent = (props) => {
  const { times, minimumAge, startTime } = props;
  return (
    <div className="col s12 m6 l4">
      <div className="info-card__label">Date and Time</div>
      <div className="info-card__stats">
        <div className="info-card__stats__primary">{startTime}</div>
        <div className="info-card__stats__secondary">
          {startTime ? times : ""}
        </div>
        <div className="info-card__stats__secondary">
          {minimumAge ? (
            <span>{`${minimumAge}+`}</span>
          ) : (
            <span>{"ALL AGES"}</span>
          )}
        </div>
      </div>
    </div>
  );
};
