import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { TicketsContainer } from "tf-checkout-react";
import { setTickets } from "@actions/tickets";
import { setCheckoutData } from "@actions/checkout";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import ActionsSectionComponent from "./actions-section";
import {
  EVENT_REVIEW_UNRECOMMEND,
  EVENT_REVIEW_RECOMMEND,
} from "@constants/config";
import { navigate } from "gatsby";
import { isWindowDefined } from "../../../assets/third-party/blast";

class EventDetailTicketing extends Component {
  state = {
    isLoading: false
  }
  handleDidRecommendClick = () => {
    const { recommendEvent, didRecommend } = this.props;
    recommendEvent({
      value: didRecommend() ? EVENT_REVIEW_UNRECOMMEND : EVENT_REVIEW_RECOMMEND,
    });
  };

  render() {
    const {
      setTickets,
      setCheckoutData,
      ttfEvent,
      browser,
      didRecommend,
      auth: {
        me: { exist, checked },
      },
      id,
      ticket_url,
      slug,
    } = this.props;
    const {
      isLoading
    } = this.state

    const isMobile = browser.lessThan.mediumSmall || browser.is.mediumSmall;
    const hideRecommendSide = !exist || !id;
    const eventIsRecommended = didRecommend();
    const userIsLoggedIn = exist && checked;
    const isSalesClosed = moment().isAfter(ttfEvent.sales_end);
    const isSalesNotStarted = !ttfEvent.sales_started;
    const externalTicketsUrl = !!(ticket_url || _get(ttfEvent, "redirect_url"));
    const noneTicketingEvent = ttfEvent.none_ticketing_event;
    const pk = isWindowDefined
      ? new URLSearchParams(window.location.search).get("pk")
      : null;

    // Hide TicketsContainer
    if (noneTicketingEvent) {
      if (isMobile) {
        return (
          <ActionsSectionComponent
            recommendState={eventIsRecommended}
            handleDidRecommendClick={this.handleDidRecommendClick}
            hideRecommendSide={hideRecommendSide}
            hideGetTicketsSide={true}
          />
        );
      }
      return null;
    }

    return (
      !_isEmpty(ttfEvent) &&
      !pk && (
        <div>
          <div className="event-detail__ticketing-card">
            <TicketsContainer
              eventId={id}
              onAddToCartSuccess={({
                skip_billing_page,
                names_required,
                age_required,
                event_id,
                hash,
                total,
                hasAddOn,
              }) => {
                if (hasAddOn) {
                  navigate(`/add-ons?event_id=${event_id}&slug=${slug}`);
                } else if (skip_billing_page) {
                  setCheckoutData({ hash, total });
                  navigate(`/checkout/${hash}?slug=${slug}`);
                } else {
                  navigate(
                    `/billing-info?age_required=${age_required}&names_required=${names_required}&event_id=${event_id}&slug=${slug}`
                  );
                }
              }}
              onGetTicketsSuccess={(res) => {
                const tickets = _get(res, "attributes.tickets", []);
                setTickets(tickets);
              }}
              isPromotionsEnabled={
                _get(ttfEvent, "is_promotions_enabled") || false
              }
              onAddToCartError={() => {
                this.setState({ isLoading: false })
              }}
              isAccessCodeEnabled={_get(ttfEvent, "is_access_code") || false}
              disableCountdownLeadingZero={true}
              hideSessionButtons={true}
              hideWaitingList={true}
              isButtonScrollable={true}
              onGetTicketsPress={() => {
                this.setState({ isLoading: true })
              }}
              actionsSectionComponent={
                isMobile ? (
                  <ActionsSectionComponent
                    recommendState={eventIsRecommended}
                    handleDidRecommendClick={this.handleDidRecommendClick}
                    hideRecommendSide={hideRecommendSide}
                    hideGetTicketsSide={
                      isSalesClosed || externalTicketsUrl || isSalesNotStarted
                    }
                    isLoading={isLoading}
                  />
                ) : null
              }
              isLoggedIn={userIsLoggedIn}
              ticketsHeaderComponent={
                <div className="tickets-container-header">TICKETS</div>
              }
              hideTicketsHeader={externalTicketsUrl}
              enableInfluencersSection={false}
              currencySybmol="$"
              showAlertIcons={false}
              getTicketsLabel={isLoading ? "We\u2019re getting your tickets now…" : "GET TICKETS"}
            />
          </div>
        </div>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTickets: (data) => {
      dispatch(setTickets(data));
    },
    setCheckoutData: (data) => {
      dispatch(setCheckoutData(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(EventDetailTicketing);
