import React, { Component } from "react";
import CopyUrl from "../../../../../components/global/social/copy-url";
import { DateTimeComponent } from "./DateTimeComponent";
import { LocationComponent } from "./LocationComponent";
import TwitterIcon from "@assets/svg/svgui/twitter-icon.svg";
import FBIcon from "@assets/svg/svgui/fb-icon.svg";
import EmailIcon from "@assets/svg/svgui/email-icon.svg";
import ExternalLinkIcon from "@assets/svg/svgui/external-link-icon.svg";

export class EventStats extends Component {
  render() {
    const {
      id,
      name = "",
      startTime,
      rsvp_url,
      platform,
      fb_url,
      times,
      ttfVenue,
      minimumAge,
    } = this.props;

    const copyURL = window.location.href;

    // fb
    const fbURL = fb_url ? fb_url : "https://www.facebook.com/events/" + id;
    const showFbIcon = platform === "fb" || fb_url;

    // rsvp
    const showRSVPIcon = rsvp_url;

    return (
      <div className="row row__stats">
        <DateTimeComponent
          startTime={startTime}
          minimumAge={minimumAge}
          times={times}
        />
        <LocationComponent ttfVenue={ttfVenue} />
        <div className="col s12 m12 l4">
          <div className="info-card__label social">{"Share Event"}</div>
          <div className="info-card__stats social">
            <div className="info-card__stats__primary">
              <div className="info-card__social">
                {<CopyUrl url={copyURL} size={"35px"} />}
                <a
                  href={
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    encodeURIComponent(copyURL)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="SVGInline fb-icon">
                    <FBIcon
                      className="SVGInline-svg fb-icon-svg"
                      style={{ width: 35, height: 35 }}
                    />
                  </div>
                </a>
                <a
                  href={
                    "https://twitter.com/intent/tweet?url=" +
                    encodeURIComponent(copyURL)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="SVGInline twitter-icon">
                    <TwitterIcon
                      className="SVGInline-svg twitter-icon-svg"
                      style={{ width: 35, height: 35 }}
                    />
                  </div>
                </a>
                <a
                  href={
                    "mailto:?body=" +
                    encodeURIComponent(copyURL) +
                    "&subject=" +
                    name
                  }
                >
                  <div className="SVGInline email-icon">
                    <EmailIcon
                      className="SVGInline-svg email-icon-svg"
                      style={{ width: 35, height: 35 }}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="info-card__stats external-link clearfix">
            {showFbIcon && (
              <a
                href={fbURL}
                className="link__more-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{"MORE INFO"}</span>
                <span className="SVGInline svg__external-link-icon">
                  <ExternalLinkIcon
                    className="SVGInline-svg svg__external-link-icon-svg"
                    style={{ width: 8, height: 8 }}
                  />
                </span>
              </a>
            )}
            {showRSVPIcon && (
              <a
                href={rsvp_url}
                className="link__more-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>{"RSVP HERE"}</span>
                <span className="SVGInline svg__external-link-icon">
                  <ExternalLinkIcon
                    className="SVGInline-svg svg__external-link-icon-svg"
                    style={{ width: 8, height: 8 }}
                  />
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
