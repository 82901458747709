import React from "react";
import _get from "lodash/get";

export const LocationComponent = ({ ttfVenue }) => {
  const ttf_city = _get(ttfVenue, "city") || "";
  const ttf_name = _get(ttfVenue, "name") || "";
  const ttf_postal_code = _get(ttfVenue, "postal_code") || "";
  const ttf_state = _get(ttfVenue, "state") || "";
  const ttf_street = _get(ttfVenue, "street") || "";
  const ttf_street_number = _get(ttfVenue, "street_number") || "";
  const venueHidden = _get(ttfVenue, "venue_hidden") || false;

  const getLocationString = () => {
    let streetFullName = ttf_street_number;
    let cityFullName = ttf_city;

    if (!venueHidden && ttf_street) {
      streetFullName += ` ${ttf_street}`;
    }

    if (ttf_state) {
      cityFullName += `, ${ttf_state} ${ttf_postal_code}`;
    }

    return (
      <div className="info-card__stats__secondary info-card__stats_text">
        {streetFullName}
        {!venueHidden && <br />}
        {cityFullName}
      </div>
    );
  };

  return (
    <div className="col s12 m6 l4">
      <div className="info-card__label">{"Location"}</div>
      <div className="info-card__stats">
        <div className="info-card__stats__primary">
          {venueHidden ? "PRIVATE LOCATION TBA" : ttf_name}
        </div>
        {getLocationString()}
      </div>
    </div>
  );
};
