import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import linkifyStr from "linkifyjs/html";
import { DEFAULT_AVATAR } from "@constants/config";
import { showToast } from "../../../actions/ui";

class BrandTile extends Component {
  getBio = (bio = "") => {
    if (bio.substring(0, 3) !== "<p>") {
      bio = bio.replace(/\n/g, "<br />");
    }

    bio = bio.replace(/<a\s+href=/gi, '<a target="_blank" href=');
    return linkifyStr(bio);
  };

  render() {
    const { type, column, brand, showLongBio } = this.props;
    const { bio, shortBio, displayName, logo, slug } = brand;

    const nameEl = displayName.replace(" ", "<br />");
    const showBio = showLongBio ? bio || shortBio : shortBio;

    return (
      <div className={column} data-type={type}>
        <div className={`content-tile content-tile--${type}`}>
          <div className="content-tile__content">
            <div className="content-tile__profile-picture">
              <Link to={`/${slug}`}>
                <img alt="" src={logo || DEFAULT_AVATAR} />
              </Link>
            </div>
            <div className={`content-tile__title--${type}`}>
              <Link
                to={`/${slug}`}
                dangerouslySetInnerHTML={{ __html: nameEl }}
              />
            </div>

            <div
              className="content-tile__description--short-bio"
              dangerouslySetInnerHTML={{ __html: this.getBio(showBio) }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message) => {
      dispatch(showToast(message));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandTile);
