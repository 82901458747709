import React, { Component } from "react";
import linkifyStr from "linkifyjs/html";
import { RsvpContainer } from "tf-checkout-react";
import _get from "lodash/get";
import EventDetailTicketing from "../../../components/modules/event-detail/event-detail-ticketing";
import BrandTile from "../../../components/global/tiles/brand-tile";
import { PROMOTER_TILE } from "../../../constants/content-tile-types";
import { renderTags as renderTagsUtil } from "../../../utils/render-tags";

const detailCardThreshold = 300;

class EventDetailContent extends Component {
  componentDidMount = () => {
    this.detailCardOffset = true;
    this.scrollHandler = (e) => this.onScrollHandler(e);
    if (window) {
      window?.addEventListener("scroll", this.scrollHandler);
    }
  };

  componentWillUnmount = () => {
    window && window?.removeEventListener("scroll", this.scrollHandler);
  };

  onScrollHandler = (e) => {
    if (this.props.blast_id && !this.props.browser.lessThan.large) {
      if (
        window &&
        window.scrollY < detailCardThreshold &&
        !this.detailCardOffset
      ) {
        this.detailCardOffset = true;
        this.applyDetailCardTransform();
      } else if (
        window &&
        window.scrollY > detailCardThreshold &&
        this.detailCardOffset
      ) {
        this.detailCardOffset = false;
        this.applyDetailCardTransform();
      }
    }
  };

  applyDetailCardTransform = () => {
    if (this.detailCardOffset) {
      this.detailCards.classList.remove("clear-offset");
    } else {
      this.detailCards.classList.add("clear-offset");
    }
  };

  renderHosts = () => {
    const { brand } = this.props;
    if (!brand) {
      return null;
    }
    return <BrandTile type={PROMOTER_TILE} column="col s12" brand={brand} />;
  };

  getDescription = () => {
    let str = this.props.ttfEvent.description;

    // LEGACY SUPPORT FOR TEXTAREA DESCRIPTIONS
    if (this.props.ttfEvent.description.substring(0, 3) !== "<p>") {
      str = str.replace(/\n/g, "<br />");
    }

    str = str.replace(/<a\s+href=/gi, '<a target="_blank" href=');
    return linkifyStr(str);
  };

  renderTags = () => {
    const metadata = JSON.parse(
      this.props?.ttfEvent.metadata?.rn_event_metadata || "{}"
    );
    const eventCategory = metadata?.eventCategory;
    const eventGenres = metadata?.eventGenres;

    const tags = [];
    if (eventCategory) {
      for (const key in eventCategory) {
        if (eventCategory[key]) {
          tags.push(key);
        }
      }
    }

    if (eventGenres) {
      for (const key in eventGenres) {
        if (eventGenres[key]) {
          tags.push(key);
        }
      }
    }

    return renderTagsUtil(
      this.props.id,
      tags.join(","),
      false,
      this.props.updateFilter,
      this.props.categoryFilters,
      this.props.locationFilters
    );
  };

  render() {
    const {
      ttfEvent,
      brand,
      browser,
      didRecommend,
      recommendEvent,
      auth,
      ticket_url,
      slug,
    } = this.props;
    const showRSVPSection =
      _get(ttfEvent, "rsvp_enabled") &&
      !_get(ttfEvent, "none_ticketing_event");

    return (
      <div className="container container__event-detail-content">
        <div className="row">
          <div className="col s10 offset-s1 m12 m-center l12 l-center xl12 xl-center">
            <div className="row">
              <div className="col s12 m6 l7 offset-xl1 xl6">
                <div className="event-detail__description">
                  {ttfEvent.description && ttfEvent.description !== "" && (
                    <div
                      className="event-detail__description-content"
                      dangerouslySetInnerHTML={{
                        __html: this.getDescription(),
                      }}
                    />
                  )}
                </div>
                <div className="event-detail__categories">
                  {this.renderTags()}
                </div>
              </div>
              <div
                ref={(ref) => {
                  this.detailCards = ref;
                }}
                className={`col s12 m6 l5 xl4 offset-xl1 col__detail-cards`}
              >
                <EventDetailTicketing
                  ttfEvent={ttfEvent}
                  browser={browser}
                  didRecommend={didRecommend}
                  recommendEvent={recommendEvent}
                  auth={auth}
                  id={ttfEvent.id}
                  ticket_url={ticket_url}
                  slug={slug}
                />
                <RsvpContainer
                  showSection={showRSVPSection}
                  eventId={ttfEvent.id}
                />
                {brand && (
                  <div className="event-detail__description-card">
                    <div className="description-card__header">
                      {"HOSTED BY"}
                    </div>
                    <div className="description-card__content">
                      <div className="row">{this.renderHosts()}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventDetailContent;
